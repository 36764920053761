import React, { useContext } from "react";
import { Line } from "rc-progress";
import { ProjectContext } from "../ProjectContext";
import CountdownTimer from "./CountdownTimer";
import { Link } from "react-router-dom";

export default function DashboardMain() {
  const { copyaddress, account, dbbalance, dbuser, c_pool_data,formatAddress } =
    useContext(ProjectContext);

  return (
    <>
      <section className="dashboard-section body-collapse">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="row">
              <div className="col-xl-8 mt-3">
                <div className="section-content">
                  <div className="acc-details ">
                    <div className="top-area d-block">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="">
                            {/* <h4>Hello,User</h4>
                            <p className="text-white">
                              (221321) <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                            </p> */}
                            <h6 className="mt-4 text-white">My Account</h6>
                            <p
                              className="text-white mt-1 text-break"
                              onClick={() => copyaddress(account)}
                            >
                              {formatAddress(account)}
                              <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                            </p>
                            <h6 className="mt-4 text-white">Referral Address</h6>
                            <p
                              className="text-white mt-1 text-break"
                              onClick={() =>
                                copyaddress(
                                  dbuser !== null
                                    ? dbuser.ref_address
                                    : "no referral"
                                )
                              }
                            >
                              {dbuser !== null
                                ? formatAddress(dbuser.ref_address)
                                : "no referral"}
                              <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <ul className=" align-items-center mt-4 mt-mdo">
                            <li>
                              {" "}
                              <h6 className="text-white">My Rank</h6>
                            </li>
                            <li>
                              {" "}
                              <p className="text-white ">
                                {dbuser !== null
                                  ? dbuser.rank === null
                                    ? "No Rank"
                                    : dbuser.rank
                                  : "No Rank"}
                              </p>
                            </li>
                          </ul>
                          <ul className=" align-items-center mt-3">
                            <li>
                              <h6 className="text-white">Affiliate link</h6>
                            </li>
                            <li>
                              {" "}
                              <p
                                className="text-white  text-break"
                                onClick={() =>
                                  copyaddress(
                                    `${process.env.REACT_APP_LINK}slots-purchase/${account}`
                                  )
                                }
                              >
                                {process.env.REACT_APP_LINK}slots-purchase/
                                {account}{" "}
                                <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-area justify-content-evenly">
                      <div className="left-side">
                        <Link
                          to="/slots-purchase"
                          className="cmn-btn"
                          style={{ width: "253px" }}
                        >
                          {" "}
                          Purchase Membership{" "}
                        </Link>
                        <Link to="/pool-entry" className="cmn-btn">
                          Pool Entry
                        </Link>
                        <Link to="/withdrawal" className="cmn-btn">
                          Withdrawal{" "}
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-xl-4 mt-3 ">
                <div className="side-items">
                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      <h6>Last Paid Pool Account</h6>
                      <div className="view-all d-flex align-items-center">
                        <a href="javascript:void(0)">View All</a>
                        <img
                          src="assets-new/images/icon/right-arrow.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <ul className="recipients-item">
                      <li>
                        <p className="left d-flex align-items-center">
                          <img
                            src="assets/img/dash-icon/magic_booster.svg"
                            style={{ height: "45px" }}
                            alt="icon"
                          />
                          <span className="info">
                            <span>Magic Pool</span>
                            <span>{c_pool_data ? c_pool_data.last_m_pool:'-'}  ---&gt; {c_pool_data ? c_pool_data.current_m_pool:'-'} </span>
                          </span>
                        </p>
                        {/* <p className="right">
                          <span> +$345</span>
                          <span>Payment</span>
                        </p> */}
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <img
                            src="assets/img/dash-icon/earth_booster.svg"
                            style={{ height: "45px" }}
                            alt="icon"
                          />
                          <span className="info">
                            <span>Earth Pool</span>
                            <span>{c_pool_data ? c_pool_data.last_e_pool:'-'} ---&gt; {c_pool_data ? c_pool_data.current_e_pool:'-'} </span>
                          </span>
                        </p>
                        {/* <p className="right">
                          <span className="loss">-$850</span>
                          <span>Refund</span>
                        </p> */}
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <img
                            src="assets/img/dash-icon/global_booster.svg"
                            style={{ height: "45px" }}
                            alt="icon"
                          />
                          <span className="info">
                            <span>Global Pool</span>
                            <span>{c_pool_data ? c_pool_data.last_g_pool:'-'} ---&gt; {c_pool_data ? c_pool_data.current_g_pool:'-'} </span>
                          </span>
                        </p>
                        {/* <p className="right">
                          <span>+$2.050</span>
                          <span>Payment</span>
                        </p> */}
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <div className="transactions-area mt-40">
                  <div className="section-text mb-0">
                    <div className="d-flex justify-content-between mb-4">
                      <h5 className="">Withdrawal Capability </h5>
                      <h5 className="">3X</h5>
                    </div>
                    <Line
                      percent={dbuser ? dbuser.x_cper : 0}
                      strokeColor="#9f24e7"
                      trailColor="#02104d"
                    />
                    <div className="d-flex justify-content-between mt-3">
                      <h6>${dbuser ? dbuser.t_staking : 0}</h6>
                      <h6>${dbuser ? dbuser.xIncome : 0}</h6>
                      <p>${dbuser ? dbuser.x4_income : 0}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="side-items mt-3">
                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      {/* working */}
                      <h6>1X</h6>
                    </div>
                    <div className="time1 text-dark">
                      {dbuser ? dbuser.is_1x ? <h3 className="pt-1 text-success text-center">Achieved</h3> : <>
                        <h3 className="pt-1 text-warning text-center">Not Achieved</h3> 
                      </> : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="side-items mt-3">
                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      {/* working */}
                      <h6>Leadership Reward</h6>
                    </div>
                    <div className="time1 text-dark">
                      {dbuser ? dbuser.royalty_working_income ? <h3 className="pt-1 text-success text-center">Achieved</h3> : <>
                        <CountdownTimer
                          targetDate={
                            dbuser.working_date
                          }
                        />
                      </> : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="side-items mt-3">
                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      {/* non working */}
                      <h6>Performance Reward</h6>
                    </div>
                    <div className="time1 text-dark">
                      <CountdownTimer
                        targetDate={
                          dbuser
                            ? dbuser.non_working_date
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="side-items mt-3">

                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      <h6>Defi 120</h6>
                    </div>
                    <div className="time1 text-dark">
                      <CountdownTimer
                        targetDate={
                          dbuser
                            ? dbuser.defi_date
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="dashboard-section body-collapse pay">
        <div className="overlay">
          <div className="container-fruid">
            <div className="main-content">
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Reward Detail</h5>
                <div className="icon-area">

                </div>
              </div>
              <div className="gap-3 d-flex justify-content-center flex-wrap ">
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/cashback_bonus.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.cashback_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr ">Cashback Bonus</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/direct_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.direct_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">Direct Reward </span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/community_building.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.level_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">Community Building</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/working_royalty_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.w_royalty_inc).toFixed(4) : 0}
                    </h5>
                    {/* <span className="mdr">Working Royalty Reward</span> */}
                    <span className="mdr">Leadership Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/non_working_royalty_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.n_w_royalty_inc).toFixed(4) : 0}
                    </h5>
                    {/* <span className="mdr">Non Working Royalty Reward</span> */}
                    <span className="mdr">Performance Reward</span>
                  </a>
                </div>

                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/rank_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.rank_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">VIP Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/rank_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.global_inc).toFixed(4) : 0}
                    </h5>
                    {/* <span className="mdr">Global Reward</span> */}
                    <span className="mdr">Salary Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/rank_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.global_vip_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">Global VIP Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/magic_booster_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.magic_b_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">Magic Pool Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/earth_booster_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.earth_p_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">Earth Pool Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/global_pool_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.global_p_inc).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">Global Pool Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/global_pool_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      ${dbuser ? Number(dbuser.x1_income).toFixed(4) : 0}
                    </h5>
                    <span className="mdr">1x Reward</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dashboard-section body-collapse pay">
        <div className="overlay">
          <div className="container-fruid">
            <div className="main-content">
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Account statistics</h5>
                <div className="icon-area">

                </div>
              </div>
              <div className="gap-3 d-flex justify-content-center flex-wrap">
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/membership_amount.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.t_staking : 0}</h5>
                    <span className="mdr">Membership Amount</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/balance.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbbalance}</h5>
                    <span className="mdr">Balance</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/total_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.t_reward : 0}</h5>
                    <span className="mdr">Total Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/direct_team.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">{dbuser ? dbuser.d_team : 0}</h5>
                    <span className="mdr">Direct Team</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/total_team.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">{dbuser ? dbuser.team : 0}</h5>
                    <span className="mdr">Total Team</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/current_rank.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">
                      {dbuser
                        ? dbuser.rank === null
                          ? "No Rank"
                          : dbuser.rank
                        : "-"}
                    </h5>
                    <span className="mdr">Current Rank</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/next_rank.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">{dbuser ? dbuser.next_rank : "-"}</h5>
                    <span className="mdr">Next Rank</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/holding_reward.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.s_leg : "-"}</h5>
                    <span className="mdr">Strong Leg</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/donate_fund.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.o_leg : "-"}</h5>
                    <span className="mdr">Other Leg</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/total_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.ro_leg : "-"}</h5>
                    <span className="mdr">Required Businesss</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.t_withdrawal : 0}</h5>
                    <span className="mdr">Withdrawal</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.pen_withdrawal : 0}</h5>
                    <span className="mdr">Pending Withdrawal</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.d_m_entry : 0}</h5>
                    <span className="mdr">Magic Pool Entry</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.d_e_entry : 0}</h5>
                    <span className="mdr">Earth Pool Entry</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.d_g_entry : 0}</h5>
                    <span className="mdr">Global Pool Entry</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">${dbuser ? dbuser.t_gracing : 0}</h5>
                    <span className="mdr">Gracing Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets/img/dash-icon/svg-1/pending_withdrawal.svg"
                        style={{ height: "40px" }}
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$0</h5>
                    <span className="mdr">Donation Reward</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
